import React from "react";

const ButtonShareIcon = () => (
  <svg
    id="_259-share-arrow"
    data-name="259-share-arrow"
    xmlns="http://www.w3.org/2000/svg"
    width="11.837250000000001"
    height="18"
    viewBox="0 0 10.522 16"
  >
    <path
      id="Path_9530"
      data-name="Path 9530"
      d="M120.222,151.7H110.3a.3.3,0,0,1-.3-.3v-11.16a.3.3,0,0,1,.3-.3h3.9a.3.3,0,1,1,0,.6h-3.6V151.1h9.32V140.541h-3.578a.3.3,0,1,1,0-.6h3.878a.3.3,0,0,1,.3.3V151.4A.3.3,0,0,1,120.222,151.7Z"
      transform="translate(-110 -135.701)"
      fill="#121331"
    ></path>
    <path
      id="Path_9531"
      data-name="Path 9531"
      d="M161.851,58.565a.3.3,0,0,1-.209-.085l-2.482-2.416-2.436,2.414a.3.3,0,1,1-.423-.427l2.646-2.622a.3.3,0,0,1,.421,0l2.694,2.622a.3.3,0,0,1,.006.425A.3.3,0,0,1,161.851,58.565Z"
      transform="translate(-153.896 -55.342)"
      fill="#796b7a"
    ></path>
    <path
      id="Path_9532"
      data-name="Path 9532"
      d="M209.3,66.115a.3.3,0,0,1-.3-.3V55.641a.3.3,0,1,1,.6,0V65.814A.3.3,0,0,1,209.3,66.115Z"
      transform="translate(-204.039 -55.34)"
      fill="#796b7a"
    ></path>
  </svg>
);

export default ButtonShareIcon;
