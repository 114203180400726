import React from "react";

const ShareIconCopy = () => (
  <svg
    width="20"
    height="20"
    color="Gray"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.351 14.35"
  >
    <path
      id="copy-solid"
      d="M10.763,2.691V0H7.624A1.345,1.345,0,0,0,6.278,1.345V9.417a1.345,1.345,0,0,0,1.345,1.345h5.381A1.345,1.345,0,0,0,14.35,9.417V3.588H11.685A.905.905,0,0,1,10.763,2.691ZM11.66,0V2.691H14.35ZM5.381,9.866V3.588H1.345A1.345,1.345,0,0,0,0,4.933v8.072A1.345,1.345,0,0,0,1.345,14.35H6.727a1.345,1.345,0,0,0,1.345-1.345V11.66h-.9A1.8,1.8,0,0,1,5.381,9.866Z"
      fill="gray"
    ></path>
  </svg>
);

export default ShareIconCopy;
