import React from "react";

const DecreaseBIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    className="fill-NickelGray"
  >
    <g
      id="Icon_ionic-ios-minus-circle-outline"
      data-name="Icon ionic-ios-minus-circle-outline"
      transform="translate(-3.375 -3.375)"
    >
      <path
        id="Path_311"
        data-name="Path 311"
        d="M24.718,13.774H11.666a1.105,1.105,0,0,0-1.154,1.154,1.117,1.117,0,0,0,1.154,1.154H24.718a1.154,1.154,0,0,0,0-2.308Z"
        transform="translate(0.183 3.447)"
      ></path>
      <path
        id="Path_312"
        data-name="Path 312"
        d="M18.375,5.394a12.976,12.976,0,1,1-9.18,3.8,12.895,12.895,0,0,1,9.18-3.8m0-2.019a15,15,0,1,0,15,15,15,15,0,0,0-15-15Z"
        transform="translate(0 0)"
      ></path>
    </g>
  </svg>
);

export default DecreaseBIcon;
