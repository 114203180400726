import React, { useContext, useState, useEffect } from "react";
import "./ProductDetails.css";
import ProductImageSlider from "ui/product_image_slider/ProductImageSlider";
import Modaly from "ui/modal/Modaly";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { cartActions, memoizedSelectCartItems } from "rtk/slices/CartSlice";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";
import he from "he"; // A robust HTML entity encoder/decoder library
import { useNavigate } from "react-router-dom"; // Ensure to import useHistory
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
// Import SVG components
import ButtonShareIcon from "./SVGs/ButtonShareIcon";
import IncButtonIcon from "./SVGs/IncButtonIcon";
import DecreaseBIcon from "./SVGs/DecreaseBIcon";
import ShareIconCopy from "./SVGs/ShareIconCopy";
import LinkedInShareIcon from "./SVGs/LinkedInShareIcon";
import PinterestShareIcon from "./SVGs/PinterestShareIcon";
import WhatsappShareIcon from "./SVGs/WhatsappShareIcon";
import TwitterShareButtonIcon from "./SVGs/TwitterShareButtonIcon";
import FBShareButton from "./SVGs/FBShareButton";

const ProductDetailsModal = ({ item, show, handleClose, images }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [subProducts, setSubProducts] = useState([]);
  const [customOptions, setCustomOptions] = useState([]);
  const [customInputs, setCustomInputs] = useState({});
  const [productUrl, setProductUrl] = useState("");
  const [baseItemImgUrl, setBaseItemImgUrl] = useState("");
  const [itemQuantity, setItemQuantity] = useState(0); // Assuming default quantity is 1
  const [subProductQuantities, setSubProductQuantities] = useState({});
  const [totalPrice, setTotalPrice] = useState(item.price);

  const productsInState = useSelector(memoizedSelectCartItems, shallowEqual);
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    if (!item) {
      navigate("/"); // Use navigate for redirection
      return;
    }
    if (item && item.word) {
      try {
        const parsedSubProducts = JSON.parse(item.word);
        const imageUrl =
          item.image.length > 0
            ? `${process.env.REACT_APP_API_URL}${item.image[0].url}`
            : "";
        setBaseItemImgUrl(imageUrl);
        const updatedSubProducts = parsedSubProducts.map((subProduct) => ({
          ...subProduct,
          imageUrl,
        }));
        setSubProducts(updatedSubProducts);
        const initialQuantities = {};
        parsedSubProducts.forEach((_, index) => {
          initialQuantities[index] = 0;
        });
        setSubProductQuantities(initialQuantities);
      } catch (error) {
        console.error("Failed to parse sub-products:", error);
        setSubProducts([]);
      }
    }
    if (item && item.custom_options) {
      try {
        const parsedOptions = JSON.parse(item.custom_options);
        setCustomOptions(parsedOptions);
        initializeCustomInputs(parsedOptions);
      } catch (error) {
        console.error("Failed to parse custom options:", error);
        setCustomOptions([]);
      }
    }
  }, [item, navigate]);

  const initializeCustomInputs = (parsedOptions) => {
    const initialInputs = {};
    parsedOptions.forEach((option) => {
      initialInputs[option.id] = ""; // Initialize each custom option's input as empty
    });
    setCustomInputs(initialInputs);
  };

  const handleCustomInputChange = (id, value) => {
    setCustomInputs((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (item) {
      const newProductUrl = `${process.env.REACT_APP_BASE_URL}products/${item.id}`;
      setProductUrl(newProductUrl);
    }
  }, [item]);

  useEffect(() => {
    let newTotalPrice = parseFloat(item.price) * itemQuantity;
    Object.keys(customInputs).forEach((key) => {
      const option = customOptions.find(
        (option) => option.id.toString() === key
      );
      if (customInputs[key].trim() !== "") {
        newTotalPrice += parseFloat(option.price);
      }
    });
    setTotalPrice(newTotalPrice.toFixed(2)); // Assumes price is a string; adjust as needed for your data types
  }, [customInputs, itemQuantity, customOptions, item.price]);

  const handleCloseModal = () => {
    navigate(-1); // Use navigate for programmatic navigation
    handleClose();
  };

  const generateProductWhatsAppMessage = () => {
    const language = lang;
    let message = `${baseItemImgUrl} \n\n`;

    let additionalCostPerItem = 0;
    Object.keys(customInputs).forEach((key) => {
      if (customInputs[key].trim() !== "") {
        const option = customOptions.find(
          (option) => option.id.toString() === key
        );
        additionalCostPerItem += parseFloat(option.price);
        message += `${option.title}: ${customInputs[key]} (${option.price} د.ك)\n`;
      }
    });

    const pricePerItem = parseFloat(item.price) + additionalCostPerItem;
    const finalPrice = pricePerItem * itemQuantity;

    message +=
      language === "ar"
        ? `*طلب*: ${item.name}\nالكمية: ${itemQuantity}, السعر النهائي: ${finalPrice} د.ك\n`
        : `*Order*: ${item.name}\nQty: ${itemQuantity}, Final Price: ${finalPrice} KWD\n`;

    message +=
      language === "ar"
        ? `يرجى تأكيد طلبي. شكرًا لك!`
        : `Please confirm my order. Thank you!`;

    return encodeURIComponent(message);
  };

  const handleCopyToClipboard = () => {
    const productUrl = `${process.env.REACT_APP_BASE_URL}products/${item.id}`;
    copy(productUrl);
    toast.success(`copied : ${productUrl}`);
  };

  const handleAddToCart = () => {
    if (itemQuantity === 0) {
      toast.error(t("selectQuantity"));
      return;
    }

    let additionalCostPerItem = 0;
    Object.keys(customInputs).forEach((key) => {
      if (customInputs[key].trim() !== "") {
        const option = customOptions.find(
          (option) => option.id.toString() === key
        );
        additionalCostPerItem += parseFloat(option.price);
      }
    });

    const pricePerItem = parseFloat(item.price) + additionalCostPerItem;
    const finalPrice = pricePerItem * itemQuantity;

    const existingItem = productsInState.find(
      (it) => it.id === item.id && it.quantity === itemQuantity
    );

    if (!existingItem) {
      dispatch(
        cartActions.addItem({
          product: item,
          quantity: itemQuantity,
          customOptions: customInputs,
          additionalPrice: additionalCostPerItem, // Store the additional cost per item
        })
      );
      toast.success(
        `${t("productAdded")} Total: ${finalPrice} ${t("currency")}`
      );
    } else {
      toast.info(t("increaseQuantity"));
    }
  };

  const handleAddSubProductToCart = (subProduct, quantity) => {
    if (quantity === 0) {
      toast.error(t("selectQuantity"));
      return;
    }

    let additionalCostPerItem = 0;
    Object.keys(customInputs).forEach((key) => {
      if (customInputs[key].trim() !== "") {
        const option = customOptions.find(
          (option) => option.id.toString() === key
        );
        additionalCostPerItem += parseFloat(option.price);
      }
    });

    const pricePerItem = parseFloat(subProduct.price) + additionalCostPerItem;
    const finalPrice = pricePerItem * quantity;

    dispatch(
      cartActions.addItem({
        product: { ...subProduct, name: `${item.name}(${subProduct.name})` },
        quantity,
        isSubProduct: true,
        additionalPrice: additionalCostPerItem, // Store the additional cost per item
      })
    );
    toast.success(
      `${subProduct.title} Added Successfully. Total: ${finalPrice} ${t("currency")}`
    );
  };

  const handleIncreaseSubProductQuantity = (index) => {
    setSubProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: (prevQuantities[index] || 0) + 1,
    }));
  };

  const handleDecreaseSubProductQuantity = (index) => {
    setSubProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: Math.max(1, (prevQuantities[index] || 2) - 1),
    }));
  };

  // Functions to increment and decrement quantity
  const handleIncreaseQuantity = () => {
    setItemQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    setItemQuantity((prevQuantity) => Math.max(0, prevQuantity - 1)); // Prevents quantity from going below 1
  };

  const htmlString = he.decode(item.description.join("")); // Join without a comma

  return (
    <Modaly show={show} handleClose={handleCloseModal}>
      <div className="product_modal row detailsRow">
        <div className="col-lg-6 product_swiper">
          {/* Image slider */}
          <ProductImageSlider images={images} />
        </div>
        <div className={`col-lg-6 ${lang === "ar" ? "detailsRow_rtl" : ""}`}>
          {/* Product details and dynamic sub-products */}
          <div className="content_productDetails">
            <h2>{item.name}</h2>
            <div
              style={{ direction: "rtl" }}
              dangerouslySetInnerHTML={{ __html: htmlString }}
            />
            <br></br>
            <div className="itemPriceAndShareBtn">
              <h2>
                {item.price} {t("currency")}
              </h2>
            </div>
          </div>
          <div className="titleProduct">
            {/* Dynamic custom-options display */}
            {item.enable_custom_options && customOptions.length > 0 && (
              <div className="additional_custom_options">
                <span>يمكنك أيضا إضافة:</span>
                <br></br>
                <br></br> {/* Two line breaks to double the space */}
                {customOptions.map((option) => (
                  <div key={option.id} className="customOptionInput">
                    <label
                      htmlFor={`customOption-${option.id}`}
                      className="custom-option-label"
                    >
                      {`${option.title} (${option.price} د.ك)`}
                    </label>
                    <Form.Control
                      type="text"
                      className="form-control"
                      value={customInputs[option.id]}
                      onChange={(e) =>
                        handleCustomInputChange(option.id, e.target.value)
                      }
                      placeholder="أضف عبارتك الخاصة"
                    />
                    <br></br>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Dynamic sub-products display */}
          {subProducts.length > 0 && (
            <div className="additional_products">
              <h4 className="text-center mb-2"> بإمكانك اختيار</h4>
              {subProducts.map((subProduct, index) => (
                <div key={index} className="card mb-3 shadow-sm">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5 className="card-title">{subProduct.title}</h5>
                        <p className="card-text">
                          <small>{subProduct.description}</small>
                        </p>
                      </div>
                      <div className="text-right">
                        <h6 className="text-muted">
                          السعر: {subProduct.price} د.ك
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="quantity-selector">
                        <button
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() =>
                            handleDecreaseSubProductQuantity(index)
                          }
                        >
                          &ndash;
                        </button>
                        <span className="mx-2">
                          {subProductQuantities[index]}
                        </span>
                        <button
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() =>
                            handleIncreaseSubProductQuantity(index)
                          }
                        >
                          +
                        </button>
                      </div>
                      <button
                        className="custom-btn btn btn-outline-secondary btn-sm"
                        onClick={() =>
                          handleAddSubProductToCart(
                            subProduct,
                            subProductQuantities[index]
                          )
                        }
                      >
                        أضف للسلة
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Add to cart button with quantity adjustment */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="IncreaseCart" style={{ width: "100%" }}>
              <div
                className="counterCart"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "0 20px",
                }}
              >
                <button className="decButton" onClick={handleDecreaseQuantity}>
                  <DecreaseBIcon />
                </button>
                <span>{itemQuantity}</span>
                <button className="incButton" onClick={handleIncreaseQuantity}>
                  <IncButtonIcon />
                </button>
              </div>
              <button
                className="buttonToCart"
                onClick={handleAddToCart}
                type="button"
              >
                <span>
                  {item.price} {t("currency")}
                </span>{" "}
                {t("buttonAdd")}
              </button>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {item["words_title"] === "exist" && (
              <a
                href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${generateProductWhatsAppMessage()}`}
                target="_blank"
                className="buttonToCart contactWhats"
                rel="noreferrer"
              >
                <i
                  className="ri-whatsapp-line"
                  style={{ marginLeft: "10px" }}
                ></i>
                <span>
                  {lang === "ar" ? "للطلب عبر الواتساب" : "Order via WhatsApp"}
                </span>
              </a>
            )}
          </div>
        </div>
        <div className="shareBtn checkoutcontainer_ar">
          <button className="share">
            <ButtonShareIcon />
            {t("buttonShare")}
          </button>
        </div>
        <div className={`sharableIcons sharableIconsActive`}>
          <FacebookShareButton url={productUrl} quote={item.name}>
            <FBShareButton />
          </FacebookShareButton>
          <TwitterShareButton url={productUrl} quote={item.name}>
            {" "}
            <TwitterShareButtonIcon />{" "}
          </TwitterShareButton>
          <WhatsappShareButton url={productUrl} quote={item.name}>
            {" "}
            <WhatsappShareIcon />{" "}
          </WhatsappShareButton>
          <PinterestShareButton url={productUrl} quote={item.name}>
            {" "}
            <PinterestShareIcon />{" "}
          </PinterestShareButton>
          <LinkedinShareButton url={productUrl} quote={item.name}>
            {" "}
            <LinkedInShareIcon />{" "}
          </LinkedinShareButton>
          <button className="shareIcon copy" onClick={handleCopyToClipboard}>
            <ShareIconCopy />{" "}
          </button>
        </div>
      </div>
    </Modaly>
  );
};

export default ProductDetailsModal;
