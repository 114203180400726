import React from "react";

const IncButtonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    className="fill-defaultThemeColor"
  >
    <g
      id="Icon_ionic-ios-add-circle-outline"
      data-name="Icon ionic-ios-add-circle-outline"
      transform="translate(-3.375 -3.375)"
    >
      <path
        id="Path_311"
        data-name="Path 311"
        d="M24.7,17.031H19.336V11.664a1.153,1.153,0,1,0-2.305,0v5.366H11.664a1.1,1.1,0,0,0-1.153,1.153,1.116,1.116,0,0,0,1.153,1.153h5.366V24.7a1.116,1.116,0,0,0,1.153,1.153A1.147,1.147,0,0,0,19.336,24.7V19.336H24.7a1.153,1.153,0,0,0,0-2.305Z"
        transform="translate(0.192 0.192)"
      ></path>
      <path
        id="Path_312"
        data-name="Path 312"
        d="M18.375,5.394a12.976,12.976,0,1,1-9.18,3.8,12.895,12.895,0,0,1,9.18-3.8m0-2.019a15,15,0,1,0,15,15,15,15,0,0,0-15-15Z"
        transform="translate(0 0)"
      ></path>
    </g>
  </svg>
);

export default IncButtonIcon;
