import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCategories = createAsyncThunk(
  "CategorySlice/fetchCategories",
  async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}api/categories`);
    const data = await res.json();
    // console.log("Fetched Categories Data:"); // Log the fetched data
    // console.log(data); // Log the fetched data

    return data;
  }
);

const initialState = {
  data: [],
  loading: false,
};

const CategorySlice = createSlice({
  initialState,
  name: "CategorySlice",
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = CategorySlice.actions;
export default CategorySlice.reducer;
