import React from "react"; // Import React library
import "./ProductCard.css"; // Import CSS file for styling
import { useTranslation } from "react-i18next"; // Import useTranslation hook for internationalization
import he from "he"; // A robust HTML entity encoder/decoder library

const ProductCard = ({ item, handleShow, AddToCart }) => {
  // Destructure props to get item, handleShow, and AddToCart functions
  const { t } = useTranslation(); // Get translation function

  // Log the entire `item` object
  // console.log("ProductCard item:", item);

  // Check if the item should be visible
  if (item.visible === 0) {
    return null; // Don't render anything if `item.visible` is 0
  }

  // Function to truncate and decode HTML entities in the description
  const truncateAndDecodeDescription = (description) => {
    if (!description) return "";

    // Decode HTML entities. Note: This does not remove or handle HTML tags.
    const decodedDescription = he.decode(description);
    const plainText = decodedDescription.replace(/<[^>]*>?/gm, ""); // Optional: Strip HTML to get plain text if needed
    const slicedText = plainText.split(" ").slice(0, 20).join(" ");

    return slicedText + (plainText.split(" ").length > 20 ? "..." : "");
  };

  // Assuming item.description is a string that could contain HTML entities
  const descriptionToDisplay = truncateAndDecodeDescription(
    item.description[0]
  );

  return (
    <div key={item.id}>
      {/* Container for the product card */}
      <div className="productCard" onClick={handleShow}>
        {/* Content section of the card */}
        <div className="cardContent">
          {/* Product name */}
          <h4>{item.name}</h4>
          {/* Call truncateDescription to display the truncated description */}
          <p>{descriptionToDisplay}</p>

          {/* Price and currency */}
          <div className="d-flex align-items-center gap-2">
            <span>{item.price}</span>
            <span>{t("currency")}</span>
            {/* Add to cart button */}
            <svg
              onClick={(e) => {
                e.stopPropagation(); // Prevent event bubbling
                AddToCart(item); // Add item to cart
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              height="30"
              width="30"
              className=""
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <div className="cardImg">
          <img
            src={`${process.env.REACT_APP_API_URL}${item.image[0].url}`}
            alt="categoryImg1"
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ProductCard;
